var closePopupTimeout;
var arCuMessages = ["", "", "Ligue"];
var arCuLoop = false;
var arCuCloseLastMessage = false;
var arCuPromptClosed = false;
var _arCuTimeOut = null;
var arCuDelayFirst = 2000;
var arCuTypingTime = 2000;
var arCuMessageTime = 4000;
var arCuClosedCookie = 0;
var arcItems = [];
var ajaxUrl = null;

function arCuGetCookie(cookieName){
    if (document.cookie.length > 0) {
        c_start = document.cookie.indexOf(cookieName + "=");
        if (c_start != -1) {
            c_start = c_start + cookieName.length + 1;
            c_end = document.cookie.indexOf(";", c_start);
            if (c_end == -1) {
                c_end = document.cookie.length;
            }
            return unescape(document.cookie.substring(c_start, c_end));
        }
    }
    return 0;
};

function arCuCreateCookie(name, value, days){
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function arCuShowMessage(index){
    if (arCuPromptClosed){
        return false;
    }
    if (typeof arCuMessages[index] !== 'undefined'){
        $('#arcontactus').contactUs('showPromptTyping');

        _arCuTimeOut = setTimeout(function(){
            if (arCuPromptClosed){
                return false;
            }
            $('#arcontactus').contactUs('showPrompt', {
                content: arCuMessages[index]
            });
            index ++;
            _arCuTimeOut = setTimeout(function(){
                if (arCuPromptClosed){
                    return false;
                }
                arCuShowMessage(index);
            }, arCuMessageTime);
        }, arCuTypingTime);
    }else{
        if (arCuCloseLastMessage){
            jQuery('#arcontactus').contactUs('hidePrompt');
        }
        if (arCuLoop){
            arCuShowMessage(0);
        }
    }
}

function arCuShowMessages(){
    setTimeout(function(){
        clearTimeout(_arCuTimeOut);
        arCuShowMessage(0);
    }, arCuDelayFirst);
}


window.addEventListener('load', function () {

    arCuClosedCookie = arCuGetCookie('arcu-closed');

    $('#arcontactus').on('arcontactus.init', function () {
        // $.mask.definitions['#'] = "[0-9]";
        // $('#arcontactus .arcu-field-phone').arCuMask('XXX-XXX-XXX');
        if (arCuClosedCookie) {
            return false;
        }
        arCuShowMessages();
    });

    $('#arcontactus').on('arcontactus.closeMenu', function () {
        arCuCreateCookie('arcumenu-closed', 1, 1);
    });

    $('#arcontactus').on('arcontactus.openMenu', function () {
        clearTimeout(_arCuTimeOut);
        if (!arCuPromptClosed) {
            arCuPromptClosed = true;
            $('#arcontactus').contactUs('hidePrompt');
        }
    });

    $('#arcontactus').on('arcontactus.openCallbackPopup', function () {
        clearTimeout(_arCuTimeOut);
        if (!arCuPromptClosed) {
            arCuPromptClosed = true;
            $('#arcontactus').contactUs('hidePrompt');
        }
    });

    $('#arcontactus').on('arcontactus.hidePrompt', function () {
        clearTimeout(_arCuTimeOut);
        if (arCuClosedCookie != "1") {
            arCuClosedCookie = "1";
            arCuPromptClosed = true;
            arCuCreateCookie('arcu-closed', 1, 0);
        }
    });

    $('#arcontactus').on('arcontactus.successCallbackRequest', function () {
        closePopupTimeout = setTimeout(function () {
            $('#arcontactus').contactUs('closeCallbackPopup');
        }, 10000);
    });

    $('#arcontactus').on('arcontactus.closeCallbackPopup', function () {
        clearTimeout(closePopupTimeout);
    })

    $('#arcontactus-storefront-btn').click(function(e){
        e.preventDefault();
        setTimeout(function(){
            $('#arcontactus').contactUs('openMenu');
        }, 200);
    });
    /*
        var arcItem = {};
        //arcItem.id='msg-item-4';
        //arcItem.class='msg-item-facebook-messenger';
        arcItem.title = "Menssenger";
        arcItem.icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M224 32C15.9 32-77.5 278 84.6 400.6V480l75.7-42c142.2 39.8 285.4-59.9 285.4-198.7C445.8 124.8 346.5 32 224 32zm23.4 278.1L190 250.5 79.6 311.6l121.1-128.5 57.4 59.6 110.4-61.1-121.1 128.5z"></path></svg>';
        arcItem.href = ' https://m.me/LSCautomoveisBraga';
        arcItem.color = '#0084FF';
        arcItems.push(arcItem);

        var arcItem = {};
        //arcItem.id='msg-item-2';
        //arcItem.class='msg-item-whatsapp';
        arcItem.title = "Whatsapp";
        arcItem.icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg>';
        arcItem.href = 'https://api.whatsapp.com/send?phone=+351937094859&text=&source=&data=';
        arcItem.color = '#1EBEA5';
        arcItems.push(arcItem);

        var arcItem = {};
        //arcItem.id='msg-item-6';
        //arcItem.class='msg-item-envelope';
        arcItem.title = "Enviar email";
        arcItem.icon = '<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"></path></svg>';
        arcItem.href = 'mailto:geral@lscautomoveis.pt';
        arcItem.color = '#FF643A';
        arcItems.push(arcItem);*/

    var arcItem = {};
    //arcItem.id='msg-item-7';
    //arcItem.class='msg-item-phone';
    arcItem.title = "Rafael - 966 055 770";
    arcItem.icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg>';
    arcItem.href = 'tel:+351966055770';
    arcItem.color = '#3EB891';
    arcItems.push(arcItem);

    var arcItem = {};
    //arcItem.id='msg-item-7';
    //arcItem.class='msg-item-phone';
    arcItem.title = "Paulo - 938 761 710";
    arcItem.icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg>';
    arcItem.href = 'tel:+351938761710';
    arcItem.color = '#3EB891';
    arcItems.push(arcItem);

    var arcItem = {};
    //arcItem.id='msg-item-7';
    //arcItem.class='msg-item-phone';
    arcItem.title = "Pedro - 913 320 597";
    arcItem.icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg>';
    arcItem.href = 'tel:+351913320597';
    arcItem.color = '#3EB891';
    arcItems.push(arcItem);

    var arcItem = {};
    //arcItem.id='msg-item-8';
    //arcItem.class='msg-item-comment-dots-light';
    arcItem.title = "Portugal - 913 320 597";
    arcItem.icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg>';
    arcItem.href = 'https://api.whatsapp.com/send?phone=351913320597&text=&source=&data=';
    arcItem.color = '#3eb891';
    arcItems.push(arcItem);

    var arcItem = {};
    //arcItem.id='msg-item-6';
    //arcItem.class='msg-item-envelope';
    arcItem.title = "Enviar email para geral@route378.pt";
    arcItem.icon = '<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h416c8.8 0 16 7.2 16 16v41.4c-21.9 18.5-53.2 44-150.6 121.3-16.9 13.4-50.2 45.7-73.4 45.3-23.2.4-56.6-31.9-73.4-45.3C85.2 197.4 53.9 171.9 32 153.4V112c0-8.8 7.2-16 16-16zm416 320H48c-8.8 0-16-7.2-16-16V195c22.8 18.7 58.8 47.6 130.7 104.7 20.5 16.4 56.7 52.5 93.3 52.3 36.4.3 72.3-35.5 93.3-52.3 71.9-57.1 107.9-86 130.7-104.7v205c0 8.8-7.2 16-16 16z"></path></svg>';
    arcItem.href = 'mailto:geral@route378.pt';
    arcItem.color = '#FF643A';
    arcItems.push(arcItem);

    /*var arcItem = {};
    //arcItem.id='msg-item-9';
    //arcItem.class='msg-item-comment-smile-light';
    arcItem.title = "Quero ser contactado";
    arcItem.icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 384c-28.3 0-56.3-4.3-83.2-12.8l-15.2-4.8-13 9.2c-23 16.3-58.5 35.3-102.6 39.6 12-15.1 29.8-40.4 40.8-69.6l7.1-18.7-13.7-14.6C47.3 313.7 32 277.6 32 240c0-97 100.5-176 224-176s224 79 224 176-100.5 176-224 176zm75.8-130.7C313 307.4 285.4 320 256 320s-57-12.6-75.8-34.6c-5.7-6.7-15.8-7.4-22.5-1.8-6.8 5.8-7.5 15.8-1.8 22.6C180.7 335.3 217.2 352 256 352s75.3-16.7 100.2-45.9c5.8-6.7 4.9-16.8-1.8-22.6-6.7-5.7-16.8-4.9-22.6 1.8zM192 216c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm128 0c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24z"></path></svg>';
    arcItem.href = 'callback';
    arcItem.color = '#4EB625';
    arcItems.push(arcItem);*/

    $('#arcontactus').contactUs({
        buttonIcon: '<svg viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Canvas" transform="translate(-825 -308)"><g id="Vector"><use xlink:href="#path0_fill0123" transform="translate(825 308)" fill="currentColor"></use></g></g><defs><path id="path0_fill0123" d="M 19 4L 17 4L 17 13L 4 13L 4 15C 4 15.55 4.45 16 5 16L 16 16L 20 20L 20 5C 20 4.45 19.55 4 19 4ZM 15 10L 15 1C 15 0.45 14.55 0 14 0L 1 0C 0.45 0 0 0.45 0 1L 0 15L 4 11L 14 11C 14.55 11 15 10.55 15 10Z"></path></defs></svg>',
        mode: 'regular',
        //buttonIconUrl: 'https://lscautomoveis.pt/wp-content/plugins/ar-contactus/res/img/msg.svg',
        showMenuHeader: false,
        menuHeaderText: "",
        showHeaderCloseBtn: false,
        itemsIconType: '',
        align: 'right',
        reCaptcha: false,
        reCaptchaKey: '',
        countdown: 0,
        theme: '#25d366',
        buttonText: "Ligue",
        buttonSize: 'medium',
        menuSize: '',
        phonePlaceholder: 'XXX-XXX-XXX',
        callbackSubmitText: 'Enviar pedido',
        errorMessage: 'Problema de ligação. Atualize a página e tente novamente.',
        callProcessText: 'A enviar pedido....',
        callSuccessText: 'Obrigado.<br />Iremos contatá-lo o mais breve possível.',
        iconsAnimationSpeed: 600,
        callbackFormText: 'Insira o seu número de telemóvel e nós ligamos consigo 🙂',
        items: arcItems,
        ajaxUrl: ajaxUrl,
        promptPosition: 'top',
        callbackFormFields: {
            name: {
                name: 'name',
                enabled: true,
                required: true,
                type: 'text',
                label: "Nome",
                placeholder: "O seu nome",
            },
            email: {
                name: 'email',
                enabled: true,
                required: true,
                type: 'email',
                label: "Email",
                placeholder: "O seu email",
            },
            phone: {
                name: 'phone',
                enabled: true,
                required: true,
                type: 'tel',
                label: 'Telemóvel',
                placeholder: "XXX-XXX-XXX"
            },
            gdpr: {
                name: 'gdpr',
                enabled: true,
                required: true,
                type: 'checkbox',
                label: "Eu aceito as leis de RGPD.",
            }
        },
        //action: 'arcontactus_request_callback'
    });
});
